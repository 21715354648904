



import Api from '@/services/Index';

const registrationNumbersByFaculty = async (config) => {
    return Api.get('/student/reports/registration-numbers-by-faculty', config);
}

const numberOfStudentByStatus = async (config) => {
    return Api.get('/student/reports/number-of-students-by-status', config);
}
const graduationDataOfRegistrar = async (config) => {
    return Api.get('/student/reports/graduation-data-report', config);
}
const demographicsOfCurrentStudents = async (config) => {

    return Api.get('/student/reports/demographics-current-students-report', config);

}
const numberOfCurrentStudentsBetweenSelectedDates = async (config) => {

    return Api.get('/student/reports/number-of-current-students-report', config);

}
const listOfStudentsInTheSelectedAcademicYear = async (config) => {

    return Api.get('/student/reports/list-of-students-report', config);

}
const undergraduateStudentsWhoTakeLessThanFullLoadOfCourses = async (config) => {
    return Api.get('/student/reports/undergraduate-students-course-list-report', config);
}
const graduateStudentsWhoTakeLessThanFullLoadOfCourses = async (config) => {
    return Api.get('/student/reports/graduate-students-course-list-report', config);
}
const studentsWhoHaveNotEnrolledInAnyClassesInTheCurrentSemester = async (config) => {
    return Api.get('/student/reports/students-not-enrolled-course-list-report', config);
}
const studentReportsStudentsYearlyCourseListReport = async (config) => {
    return Api.get('/student/reports/students-yearly-course-list-report', config);
}
const studentReportsStudentsMustCourseListReport = async (config) => {
    return Api.get('/student/reports/students-must-course-list-report', config);
}
const studentsWhoAreLikelyToGraduateInTheNext3Semesters = async (config) => {
    return Api.get('/student/reports/students-must-course-list-report', config);
}
const studentsWhoAreLikelyToGraduateInTheNext2Semesters = async (config) => {
    return Api.get('/student/reports/students-graduate-two-semester-list-report', config);
}
const studentsWhoAreLikelyToGraduateNextSemester = async (config) => {
    return Api.get('/student/reports/students-graduate-next-semester-list-report', config);
}
const studentsWhoAreLikelyToGraduateInTheCurrentSemester = async (config) => {
    return Api.get('/student/reports/students-graduate-current-semester-list-report', config);
}
const sapReport = async (config) => {
    return Api.get('/student/semesters/sap-report',config);
}
const headCount = async (config) => {
    return Api.get('/student/reports/headcount-by-major-report',config);
}
const degreeSeekingEnrollmentDegreesReport = async (config) => {
    return Api.get('/student/reports/degree-seeking-enrollment-degrees-report',config);
}
const creditHoursGeneratedReport = async (config) => {
    return Api.get('/student/reports/credit-hours-generated-report',config);
}
const diversityReport = async (config) => {
    return Api.get('/student/reports/diversity-report', config);
}
const enrollmentReport = async (config) => {
    return Api.get('/student/reports/enrollment-report', config);
}
const AdmissionReport = async (config) => {
    return Api.get('/student/reports/admission-report', config);
}
const RegistrarReport = async (config) => {
    return Api.get('/student/reports/registrar-report', config);
}
const courseApprovalCheckReport = async (config) => {
    return Api.get('/student/reports/course-approval-check-report', config);
}
const courseRegistrationReport = async (config) => {
    return Api.get('/student/courses/student-course-included-report', config);
}

const registrationReport = async (config) => {
    return Api.get('/student/reports/registration-report', config);
}
const missingDocumentReport = async (config) => {
    return Api.get('/student/programs/missing-documents-excel-export', config);
}

const studentReportsParchmentReport = async (config) => {
    return Api.get('/student/reports/parchment-report', config);
}
const studentVirtualInternshipReport = async () => {
    return Api.get('/student/reports/virtual-internships-report', {responseType:"arraybuffer"})
}

export default {
    registrationNumbersByFaculty,
    numberOfStudentByStatus,
    graduationDataOfRegistrar,
    demographicsOfCurrentStudents,
    numberOfCurrentStudentsBetweenSelectedDates,
    listOfStudentsInTheSelectedAcademicYear,
    undergraduateStudentsWhoTakeLessThanFullLoadOfCourses,
    graduateStudentsWhoTakeLessThanFullLoadOfCourses,
    studentsWhoHaveNotEnrolledInAnyClassesInTheCurrentSemester,
    studentReportsStudentsYearlyCourseListReport,
    studentReportsStudentsMustCourseListReport,
    studentsWhoAreLikelyToGraduateInTheNext3Semesters,
    studentsWhoAreLikelyToGraduateInTheNext2Semesters,
    studentsWhoAreLikelyToGraduateNextSemester,
    studentsWhoAreLikelyToGraduateInTheCurrentSemester,
    sapReport,
    headCount,
    degreeSeekingEnrollmentDegreesReport,
    creditHoursGeneratedReport,
    diversityReport,
    enrollmentReport,
    AdmissionReport,
    RegistrarReport,
    courseApprovalCheckReport,
    courseRegistrationReport,
    registrationReport,
    missingDocumentReport,
    studentReportsParchmentReport,
    studentVirtualInternshipReport
}

